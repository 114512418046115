

import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyBDAEfH-oxvtueicsZR33p9qQjgt8ENxIE",
  authDomain: "desertmina-f8320.firebaseapp.com",
  projectId: "desertmina-f8320",
  storageBucket: "desertmina-f8320.appspot.com",
  messagingSenderId: "145127813284",
  appId: "1:145127813284:web:868200e25d26bd18aef631",
  measurementId: "G-X4EK3599D3"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;