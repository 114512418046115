import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';



import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Contactus from './components/pages/Contactus';
import Journey from './components/pages/Journey';
import Aboutus from './components/pages/Aboutus';
import Career from './components/pages/Career';
import Commentary from './components/pages/Commentary';

function App() {
  return (
    <Router>
    
    <div>

     
      
      <Routes>

      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/Contactus" element={<Contactus/>}/>
      <Route axact path="/our-journey" element={<Journey/>}/>
      <Route axact path="/Aboutus" element={<Aboutus/>}/>
      <Route axact path="/career" element={<Career/>}/>
      <Route axact path="/Commentary" element={<Commentary/>}/>
      </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;
