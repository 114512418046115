import React from 'react';
// import './Footer.css';
import { Link } from 'react-router-dom';
// import LogoFooter from "../images/CLASIKFOOTER.jpg"
// import Techuniaone from "../images/techuniaone.jpg";
// import Mic from "../images/mrindiancoder.jpg";
// import logo from "../images/logojewellers.jpg";
import MRINDIANCODER from "../images/mr.indiancoder.jpg";
import "./Footer.css";


function Footer() {
  return (


    <div className="bestdrinkingwaterberhampore">
      <br></br>
      <br />
      {/* <div className="p-3 mb-0 bg-dark text-white"> */}

      <div className="album py-3">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <h6 style={{ color: "white" }}>Office Address</h6>
              <hr/>

              <ul className="list-unstyled">

                <h6 style={{ color: "white" }} className="card-text "> <b>DESERTMINA</b></h6>

                <p style={{ color: "white" }} className="card-text mb-1">Vill- Dakshingram, NH-12, P.O- Palsandamore</p>
                <p style={{ color: "white" }} className="card-text">P.S- Nabagram, Dist- Murshidabad, Pin -742238, WB | India</p>
                <a href="tel: +91 9434442245" className="btn btn-light mb-2">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                </svg> +91 9434442245</a>
                <br />
                <a href="tel: +91 9434464945" className="btn btn-light">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                </svg> +91 9434464945</a>
              </ul>
            </div>

            <div className="col-md-3">
              <h6 style={{ color: "white" }}>Quick Link</h6>
              <hr />
              <ul className="list-unstyled">




                <a href='/' style={{ color: "white" }} >
                  <li style={{ color: "white" }} className="mb-2"><a ><small>Home</small></a></li></a>
                <a href='our-journey' style={{ color: "white" }} >
                  <li style={{ color: "white" }} className="mb-2"><a ><small> Our Journey</small></a></li></a>

                


               
                  <a href='Commentary' style={{ color: "white" }} >
                  <li style={{ color: "white" }} className="mb-2"><a ><small> Commentary</small></a></li></a>
                  <a href='Aboutus' style={{ color: "white" }} >
                  <li style={{ color: "white" }} className="mb-2"><a ><small> About us</small></a></li></a>
                

              </ul>

            </div>
            <div className="col-md-3">

              <h6 style={{ color: "white" }}>Other Link</h6>
              <hr />

              <ul class="list-unstyled">

                <a href='Contactus' style={{ color: "white" }} >
                  <li style={{ color: "white" }} className="mb-2"><small>Contact </small></li></a>
                  <a href='career' style={{ color: "white" }} >
                  <li style={{ color: "white" }} className="mb-2"><a ><small> Career</small></a></li></a>
              </ul>
            </div>
            <div className="col-md-2">
              <h6 style={{ color: "white" }}>Social Link</h6>
              <hr />
              <ul className="list-unstyled">
                <li className="mb-2"> <a style={{ color: "white" }} href="https://www.facebook.com/p/Desertmina-100064995613490/" target="_blank" rel="noopener noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" style={{ color: "white" }} className="bi bi-facebook" viewBox="0 0 16 16">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                  </svg> <small> Facebook</small></a> </li>

                <li className="mb-2"> <a style={{ color: "white" }} href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" style={{ color: "white" }} className="bi bi-youtube" viewBox="0 0 16 16">
                    <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                  </svg> <small> Youtube</small></a> </li>






              </ul>

            </div>





          </div>
        </div>
      </div>


    

      <div className="album py-3">

        <div className="container">

          <div className="row">
            <div className="col-md-4">
              <div className='biman'>
                <Link to="/">
                  <h4 style={{ color: "white" }}> DESERTMINA</h4>
                </Link>
              </div>
            </div>

            <div className="col-md-4">
              <p style={{ color: "white" }}> Copyright © 2024, DESERTMINA</p>

            </div>
            <div className="col-md-4">
              <small style={{ color: "white" }}> Development & Design : <a style={{ color: "white" }} href="https://www.mrindiancoder.com/" target="_blank" rel="noopener noreferrer"> www.mrindiancoder.com</a> </small>
              <small>  <a href="https://www.mrindiancoder.com/" target="_blank" rel="noopener noreferrer"> </a> </small>
              <a href="https://www.mrindiancoder.com/" target="_blank" rel="noopener noreferrer"> <img src={MRINDIANCODER} width="85" height="30" alt="mr.indiancoder" /> </a>
            </div>

          </div>
        </div>
      </div>

    </div>
    //  </div>





  );
}
export default Footer;