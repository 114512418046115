import React from 'react'
import Navbar from '../inc/Navbar'

import AddCareerpage from '../AddCareerpage';
import ScrollToTop from "react-scroll-to-top";
function Career() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <ScrollToTop smooth top="100" color="darkBlue"/>
            <div className='desertmian'>
                <div className="album py-2">
                    <div className="container mt-4">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Career</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>


            <div className="album py-3">
                <div className="container">
                    <div className="row">

                        <div className="col-md-6 mt-3 me-5">


                            <div className="card-body">
                                <h3 className="card-title justify mb-4">Career options at D.D Industries</h3>
                                <p className="card-text justify">Be a part of Purity with the journey of Desertmina. Join our dynamic family where everbody shares a common aim that is ‘Delivering safe & best drinking water’. Take a part & get associated with a pioneering team to take yourself as well as our company forward. Experience the development, advancement & furtherance through continuous learning, supporting & assisting.  </p>
                               
                            </div>

                        </div>
                        <div className="col-md-5 mt-3">
                       <AddCareerpage/>


                    </div>
                    </div>
                   
                </div>
            </div>
            <br />

        </div>
    )
}

export default Career