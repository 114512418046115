import React from 'react'
import Navbar from '../inc/Navbar'

import desertminaowner from "../images/chairman Desertmina.jpg";
import ScrollToTop from "react-scroll-to-top";


import iso from "../images/iso.jpg";
import fssai from "../images/fssai.jpg";
import bis from "../images/bis logo.jpg";

import barcode from "../images/Bar code.jpg";


function Aboutus() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
         
            <ScrollToTop smooth top="100" color="darkBlue" />
            <div className='desertmian'>
                <div className="album py-2">
                    <div className="container mt-4">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">About us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="album py-3">
            <div className="container">
                <center>
                <h1><b>ABOUT US</b></h1>
                </center>
                </div>
                </div>

            <div className="album py-2">
                <div className="container">
                    <div className="row">
                   
                        <div className="col-md-12 mt-3">


                            <div className="card-body">
                                <h5 className="card-title justify mb-3">Desertmina: A quality product of D.D Industries</h5>
                                <p className="card-text justify">With the progress of time Desertmina procured it’s position at the forefront line in the ground of best packaged drinking water.  </p>

                                <div className='iso'>
                                <h4 className='mb-2'>An ISO 9001: 2015 Certified Company</h4>
                                </div>
                                <h5 className='mb-2'>                     <b>            Approved by </b>: BUREAU OF INDIAN STANDARDS (BIS)</h5>
                                <h5 className='mb-2'>FOOD SAFETY STANDARDS AUTHORITY OF INDIA (FSSAI)</h5>
                                <br />

                                
            <div className="album py-0">
                <div className="container">
                    <div className="row">

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                               
                                        <div className="card">
                                            <img src={iso} className="card-img-top" alt="iso"/>
                                           
                                        </div>
                                   
                                </div>
                                <div className="col-md-6  mt-3">
                               
                                        <div className="card">
                                            <img src={fssai} className="card-img-top" alt="fssai"/>
                                         
                                        </div>
                                  
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={bis} className="card-img-top" alt="bis"/>
                                        
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                        <img src={barcode} className="card-img-top" alt="barcode"/>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

                              
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <br />

        </div>
    )
}

export default Aboutus