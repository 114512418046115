import React from 'react'
import ReactPlayer from "react-player";
function Journeypage() {
  return (
    <div>
           <div className="album py-1">
                <div className="container">
                    <center>
                        <h1 className='mt-2' style={{ color: "darkBlue" }}><b>Our  Journey </b></h1>
                    </center>
                </div>
            </div>



            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mt-3">


                            <div className="card-body">
                                <h5 className="card-title justify mb-3">Since 2010 "D.D INDUSTRIES"— this name has been synonymous with Purity & Trust .Trust has been earned & sustained with care.</h5>
                                <p className="card-text justify">Desertmina is a quality product of D.D INDUSTRIES,Serving purity since 15 years . This manufacturing unit started its journey with a corrugated shed and four-five rural people, today it is equivalent to the big mineral water companies in the country in terms of quality . The realm of D.D Industries got unfurled throughout Murshidabad & partly surrounding districts. Moreover today we are standing by 400 families of our own & neighbouring areas.  Desertmina not only promises healthieness but also upskills rural  workers, bringing the surrounding rural society under improved health & safety schemes. Our packaging is designed with sustainability in mind. We use recyclable materials and follow eco-friendly practices to reduce our carbon footprint. At Desertmina, we understand that access to clean, safe drinking water is essential for your health and well-being. That’s why we are committed to providing high-quality packaged drinking water, designed to meet your hydration needs anytime, anywhere. Whether you need a quick refreshment or a large supply for your family, we offer a variety of convenient packaging options—so you can stay hydrated on the go or at home just with a grasp of Desermina.</p>
                            </div>

                        </div>
                        <div className="col-md-4 mt-3">

                            <ReactPlayer width="100%" height="100%" url="https://www.youtube.com/watch?v=TTT1vRv-I8w&t=10s" />


                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Journeypage